/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// var $ = require('jquery');

require('../../node_modules/scroll-entrance/src/scroll-entrance');
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";

tns({
    container: '.places-slider',
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 6000,
    autoplayHoverPause: true,
    controlsPosition: 'bottom',
    controlsContainer: '.places-slider-controls',
    nav: false,
    speed: 600,
});

tns({
    container: '.quotes-slider',
    autoHeight: true,
    autoplay: true,
    autoplayTimeout: 8000,
    autoplayHoverPause: true,
    controlsPosition: 'bottom',
    controlsContainer: '.quotes-slider-controls',
    nav: false,
    speed: 600,
});


document.getElementById('scroll').addEventListener('click', function(e) {
    e.preventDefault();

    const scrollEndElem = document.getElementById('product'),
        anim = requestAnimationFrame((timestamp) => {
        const stamp = timestamp || new Date().getTime(),
            duration = 600,
            start = stamp;

        const startScrollOffset = window.pageYOffset,
            scrollEndElemTop = scrollEndElem.getBoundingClientRect().top;

        scrollToElem(start, stamp, duration, scrollEndElemTop, startScrollOffset);
    });
});

const easeInCubic = t => t * t * t;

const scrollToElem = (startTime, currentTime, duration, scrollEndElemTop, startScrollOffset) => {
    const runtime = currentTime - startTime;
    let progress = runtime / duration;

    progress = Math.min(progress, 1);

    const ease = easeInCubic(progress);

    window.scroll(0, startScrollOffset + (scrollEndElemTop * ease));
    if (runtime < duration) {
        requestAnimationFrame((timestamp) => {
            const currentTime = timestamp || new Date().getTime();
            scrollToElem(startTime, currentTime, duration, scrollEndElemTop, startScrollOffset);
        })
    }
}

document.getElementById('sticky-contact').addEventListener('click', function(e) {
    e.preventDefault();
    var $contact = document.getElementById("contact");
    $contact.scrollIntoView(true);
    return false;
});

var launchModal = function(object) {
    var modal = document.getElementById('modal');
    var modalContent = document.getElementById('modal-content');

    modal.classList.add('show');
    setTimeout(function () {
        modal.classList.add('fade');
    }, 500);
    modalContent.innerHTML = video;

    function closeModal() {
        modalContent.innerHTML = '';
        modal.classList.remove('fade');
        setTimeout(function () {
            modal.classList.remove('show');
        }, 500);
    }
    document.getElementById('modal-close').onclick = closeModal;

    var modalDialog = document.getElementById('modal-dialog');
    modal.addEventListener('click', function(event) {
        var isClickInside = modalDialog.contains(event.target);

        if (!isClickInside) {
            closeModal();
        }
    });
};

document.getElementById('play-video').addEventListener('click', launchModal);

document.getElementById('preloader').classList.add('fade');
setTimeout(function () {
    document.getElementById('preloader').classList.add('hidden');
}, 500);

document.querySelector('#contact .text-error') && document.getElementsByClassName('sticky-contact')[0].click();

document.querySelector('#contact .text-success') && setTimeout(function() {
    document.querySelector('#contact .text-success').classList.add('hide');
}, 7000);
